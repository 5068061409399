<template>
  <div class="w-full h-[24rem] md:h-[36rem] rounded-lg overflow-hidden shadow-lg">
    <GMapMap
      :center="{ lat: 49.988209, lng: 36.233916 }"
      :zoom="11"
      :options="mapOptions"
      class="w-full h-full"
    >
      <GMapMarker
        v-for="parking in parkings"
        :key="parking.id"
        :position="parking.location"
        :clickable="true"
        :icon="customIcon"
      />

      <GMapPolygon
        v-for="parking in parkings"
        :key="parking.id"
        :path="parking.area"
        :options="polygonOptions"
      />
    </GMapMap>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const parkings = ref([]);
const customIcon = {
  url: 'https://cdn-icons-png.flaticon.com/512/3665/3665962.png',
  scaledSize: { width: 24, height: 24 },
};
const mapOptions = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};
const polygonOptions = {
  fillColor: '#3182ce',
  fillOpacity: 0.4,
  strokeColor: '#3182ce',
};

const fetchLocations = async () => {
  try {
    const response = await axios.get('https://api.breeze.yooud.org/parking/locations');
    parkings.value = response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
  }
};

onMounted(() => {
  fetchLocations();
});
</script>
