<template>
  <div class="container mx-auto">
    <div class="flex flex-wrap justify-center">
      <TariffCard v-for="tariff in tariffs" :key="tariff.id" :item="tariff" class="m-4" />
    </div>
  </div>
</template>

<script>
import TariffCard from './TariffCardComponent.vue';
import axios from 'axios';

export default {
  name: 'TariffList',
  components: {
    TariffCard
  },
  data() {
    return {
      tariffs: []
    };
  },
  mounted() {
    this.fetchTariffs();
  },
  methods: {
    async fetchTariffs() {
      try {
        const response = await axios.get('https://api.breeze.yooud.org/tariff/active');
        this.tariffs = response.data;
      } catch (error) {
        console.error('Error fetching tariffs:', error);
      }
    }
  }
};
</script>
