<template>
  <div class="flex flex-col md:flex-row items-center md:place-content-evenly lg:px-32 xl:px-64">
    <div class="flex flex-col font-sans text-center md:text-left md:w-1/2">

      <h1 class="text-4xl md:text-5xl font-bold">
        ПЕРЕСУВАННЯ МІСТОМ <br />ЯК <span class="font-glitch font-normal">BREEZE</span>
      </h1>

      <p class="text-lg md:text-2xl mt-4 md:mt-6 font-normal">
        Breeze - це сервіс шерінгу електросамокатів, на яких Ви можете пересуватись
        містом і забути про корки на дорогах. Просто знайдіть самокат поблизу,
        зареєструйтесь в нашому сервісі і поїхали!
      </p>

      <div class="w-full md:w-auto mt-6">
        <button @click="download()" class="w-full px-4 md:w-auto h-12 cursor-pointer bg-widgets text-white text-base md:text-lg shadow-md rounded-lg hover:bg-panels">
          Download
        </button>
      </div>

    </div>

    <div class="w-full md:w-1/2 mt-6 md:mt-0">
      <img src="../assets/banner_screens.png" class="w-full">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    download() {
      window.open('/download', '_blank');
    },
  },
};
</script>
