<template>
  <link href='https://fonts.googleapis.com/css?family=Rubik+Glitch' rel='stylesheet'>
  <link rel="stylesheet" href="../style.css">

  <header class="w-screen max-w-[100%] h-[10vh] sticky top-0 bg-panels flex items-center justify-between shadow-lg z-10 px-8 md:px-16 lg:px-32 xl:px-64">
    <div id="corpName" class="text-3xl md:text-4xl text-white flex items-center font-glitch">
      <router-link to="/" style="">
        Breeze
      </router-link>
    </div>

    <nav class="text-white text-lg md:text-xl hidden md:flex">
      <a @click="scrollTo('info')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">Інфо</a>
      <a @click="scrollTo('rental')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">Оренда</a>
      <a @click="scrollTo('map')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">Паркінги</a>
      <a @click="scrollTo('footer')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">О нас</a>
    </nav>

    <button @click="toggleMenu" class="md:hidden">
      <svg class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </button>
  </header>

  <nav v-show="isMenuOpen" class="md:hidden sticky top-[10vh] left-0 z-10 w-full bg-panels text-white text-lg md:text-xl flex flex-col items-center">
      <a @click="scrollTo('info')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">Інфо</a>
      <a @click="scrollTo('rental')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">Оренда</a>
      <a @click="scrollTo('map')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">Паркінги</a>
      <a @click="scrollTo('footer')" class="mr-4 px-2 py-1 rounded-lg transition duration-300 hover:bg-inline hover:text-gray-300">О нас</a>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    scrollTo(id) {
      const element = document.getElementById(id);
      if (element) {
        const offset = window.innerHeight * 0.1;
        const top = element.offsetTop - offset;
        window.scrollTo({top, behavior: 'smooth'});
      }
    }
  }
};
</script>
