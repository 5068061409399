<template>
  <div class="max-w-sm rounded-lg overflow-hidden shadow-lg p-6 bg-widgets transform transition-transform hover:scale-105">
    <div class="text-center mb-4">
      <div class="text-2xl font-bold text-gray-800">{{ item.name }}</div>
      <p class="text-gray-600">{{ item.description }}</p>
    </div>
    <div class="bg-inline p-4 rounded-lg">
      <p class="text-gray-900 text-lg">
        Ціна розблокування: <span class="font-bold">{{ item.initialPrice }} грн.</span>
      </p>
      <p class="text-gray-900 text-lg">
        Ціна за {{ periodTypeText }}: <span class="font-bold">{{ item.pricePerPeriod }} грн.</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    periodTypeText() {
      switch (this.item.period) {
        case 0:
          return 'сек.';
        case 1:
          return 'хв.';
        case 2:
          return 'год.';
        default:
          return 'пер.';
      }
    }
  }
};
</script>
