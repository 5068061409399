import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import './style.css';

createApp(App)
    .use(router)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDVJOvkaGuMAMCsmpmdfCe3UVGYtomdj0M',
        }
    })
    .mount('#app');
