<template>
  <footer class="bg-panels w-full h-fit bottom-0 flex text-white items-center md:justify-evenly justify-between flex-wrap px-4 sm:px-8 md:px-16 py-6" >
    <div class="md:w-fit w-full flex flex-col items-center sm:items-start">
      <img class="w-[50px] h-[50px] rounded-lg" alt="logo" src="../assets/logo.png">
      <span>breeze@gmail.com</span>
      <span>© 2024 Breeze, Inc.</span>
    </div>
    <div class="md:w-fit w-full flex items-center justify-center sm:justify-start mt-4 sm:mt-0">
      <div class="flex flex-col items-center">
        <img src="../assets/phone.svg" class="w-[30px] h-[30px]">
        <img src="../assets/mail.svg" class="w-[25px] h-[30px]">
        <img src="../assets/pin.svg" class="w-[25px] h-[25px]">
      </div>
      <div class="flex flex-col place-content-between text-left ml-2">
        <span>(096) 856 62 02</span>
        <span>breeze@gmail.com</span>
        <span>вул. Сумська 77/79, Харків</span>
      </div>
    </div>
  </footer>
</template>
