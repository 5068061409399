<template>
  <div class="mt-32 flex flex-col items-center px-4 sm:px-6 lg:px-8">
    <h2 class="text-5xl font-bold text-center text-gray-800 mb-8">
      Оренда
    </h2>
    <p class="text-2xl text-center text-gray-700 mb-6">
      Оренда здійснюється виключно через мобільний застосунок.
    </p>

    <h3 class="text-3xl font-bold text-center text-gray-800 mb-4">Тарифи</h3>

    <div class="w-full flex justify-center">
      <TariffList />
    </div>
  </div>
</template>

<script>
import TariffList from "@/components/TariffListComponent.vue";

export default {
  name: 'RentalBlock',
  components: {
    TariffList
  }
};
</script>
