<template>
  <div class="w-full bg-background px-4 lg:px-0 pt-12">
    <InfoBlock id="info"/>
    <RentalBlock id="rental" />
    <MapBlock id="map" />
  </div>
</template>

<script>
import Header from '../components/HeaderComponent.vue';
import Footer from '../components/FooterComponent.vue';
import InfoBlock from "@/components/InfoBlockComponent.vue";
import RentalBlock from "@/components/RentalBlockComponent.vue";
import MapBlock from "@/components/MapBlockComponent.vue";

export default {
  name: 'HomePage',
  components: {
    Header,
    InfoBlock,
    RentalBlock,
    MapBlock,
    Footer,
  },
};
</script>
