<template>
  <link rel="stylesheet" href="style.css">
  <Header id="header" />
  <router-view />
  <Footer id="footer"/>
</template>

<script>
import Footer from './components/FooterComponent.vue';
import Header from './components/HeaderComponent.vue';

export default {
  name: 'App',
   components: {
    Header,
    Footer,
  },
};
</script>
